import { FC, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import AddressFinder, {
  addressFinderSchema,
  defaultValues as addressFinderDefaultValues,
} from '@components/AddressFinder/AddressFinder';
import { useAddressFinderQueryStringDefaultValues } from '@components/AddressFinder/hooks/useAddressFinderQueryStringDefaultValues';
import RichText from '@components/RichText/RichText';
import { TrackedDialog } from '@components/TrackedDialog/TrackedDialog';
import { useIsLargeBusinessCheck } from '@hooks/address/useAddress';
import { createQueryString, useRedirect } from '@hooks/redirect/useRedirect';
import { useLinkComponent } from '@link';
import { SmeAddressFinderRendering } from '@sitecore/types/SmeAddressFinder';
import { Box, Button, ButtonLink, Checkbox, Form, NotificationBox, Stack, Text, TextLink } from '@sparky';

interface FormFields {
  city?: string | undefined;
  houseNumber: string;
  houseNumberSuffix?: string;
  postalCode: string;
  street?: string | undefined;
  energyUsageForBusinessPurposes?: boolean;
}

const SmeAddressFinder: FC<SmeAddressFinderRendering> = ({ fields }) => {
  const addressFinderQueryStringDefaultValues = useAddressFinderQueryStringDefaultValues();
  const redirect = useRedirect();
  const [isSuffixRequired, setIsSuffixRequired] = useState(false);
  const {
    buttonLink,
    disclaimerText,
    energyUsageForBusinessPurposeCheckbox,
    energyUsageForBusinessPurposesNotification,
    secondaryButtonLink,
  } = fields;

  const Link = useLinkComponent();

  const formSchema = yup.object({
    ...addressFinderSchema(isSuffixRequired).fields,
    energyUsageForBusinessPurposes: yup.boolean(),
  });

  const formMethods = useForm({
    defaultValues: {
      ...addressFinderDefaultValues,
      ...addressFinderQueryStringDefaultValues,
      energyUsageForBusinessPurposes: true,
    },
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });

  const { handleSubmit, register, watch } = formMethods;

  const { energyUsageForBusinessPurposes, postalCode, houseNumber, houseNumberSuffix } = watch();
  const isLargeBusiness = useIsLargeBusinessCheck({ postalCode, houseNumber, houseNumberSuffix });

  const onSubmit = ({ postalCode, houseNumber, houseNumberSuffix }: FormFields) =>
    redirect({
      queryParams: { postalCode: postalCode?.replace?.(' ', ''), houseNumber, houseNumberSuffix },
      link: buttonLink.value,
    });

  const b2cLink = useMemo(() => {
    if (!secondaryButtonLink) return '';
    const queryParams = createQueryString({
      postalCode,
      houseNumber,
      houseNumberSuffix,
    });
    return `${secondaryButtonLink.value.href}${queryParams ? `?${queryParams}` : ''}`;
  }, [secondaryButtonLink, postalCode, houseNumber, houseNumberSuffix]);

  const customLabels = {
    houseNumber: fields.houseNumberFormField,
    houseNumberSuffix: fields.houseNumberSuffixFormField,
    postalCode: fields.postalCodeFormField,
    notFoundErrorNotification: fields.notFoundErrorNotification,
    serverErrorNotification: fields.serverErrorNotification,
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AddressFinder
          customLabels={customLabels}
          isSuffixRequired={isSuffixRequired}
          setIsSuffixRequired={setIsSuffixRequired}
        />

        <Box paddingY={{ initial: 4, lg: 6 }}>
          <Stack direction="column" gap="3">
            <Checkbox
              {...register('energyUsageForBusinessPurposes')}
              label={energyUsageForBusinessPurposeCheckbox?.value.label ?? ''}
              weight="bold"
            />
          </Stack>
        </Box>
        {!energyUsageForBusinessPurposes && (
          <Box>
            <Stack gap="4">
              <NotificationBox
                isAlert={false}
                variant={energyUsageForBusinessPurposesNotification?.value.variant}
                title={energyUsageForBusinessPurposesNotification?.value.title}
                text={<RichText html={energyUsageForBusinessPurposesNotification?.value.content} />}
              />
              <Link linkType="internal" href={b2cLink}>
                <TextLink emphasis="high">{secondaryButtonLink?.value.text}</TextLink>
              </Link>
            </Stack>
          </Box>
        )}
        {(disclaimerText?.value || disclaimerText?.editable) && (
          <Box paddingTop="4">
            <Text color="textLowEmphasis">{disclaimerText.value}</Text>
          </Box>
        )}

        {energyUsageForBusinessPurposes && buttonLink?.value?.href && (
          <Box paddingTop="6">
            {isLargeBusiness ? (
              <TrackedDialog
                title={fields.enterpriseDialog.value.title}
                trigger={
                  <Button size="regular" type="button">
                    {buttonLink.value.text}
                  </Button>
                }
                width="regular">
                <Box paddingBottom="6">
                  <RichText html={fields.enterpriseDialog.value.content} />
                </Box>
                <Stack direction="row" gap={5}>
                  <ButtonLink size="regular" href={fields.enterpriseRedirectLink.value.href}>
                    {fields.enterpriseDialog.value.submitButtonText}
                  </ButtonLink>
                  <Button
                    action="secondary"
                    size="regular"
                    type="button"
                    onClick={() => onSubmit({ postalCode, houseNumber, houseNumberSuffix })}>
                    {fields.enterpriseDialog.value.cancelButtonText}
                  </Button>
                </Stack>
              </TrackedDialog>
            ) : (
              <Button size="regular" type="submit">
                {buttonLink.value.text}
              </Button>
            )}
          </Box>
        )}
      </Form>
    </FormProvider>
  );
};

export default SmeAddressFinder;
