import React, { FC, SyntheticEvent, useCallback } from 'react';

// eslint-disable-next-line dxp-rules/no-dialog-from-sparky
import { Dialog } from '@sparky';
import { DialogProps } from '@sparky/types';
import { keepFromTracking, pushToDataLayer, useTracking } from '@tracking';

interface CompoundProps {
  Close: typeof Dialog.Close;
}

export const TrackedDialog: FC<React.PropsWithChildren<DialogProps>> & CompoundProps = ({ children, ...props }) => {
  const { scope } = useTracking();

  const onCloseHandler = useCallback(
    (event?: SyntheticEvent<HTMLButtonElement, Event>) => {
      // make sure the automatic event tracking of the close button is cancelled
      keepFromTracking(event);

      // fire a custom select_button event instead
      pushToDataLayer({
        event: 'select_button',
        component_name: scope,
        component_type: 'dialog',
        selected_content: 'close',
        selected_action: 'close',
      });
    },
    [scope],
  );

  return (
    <Dialog onClose={onCloseHandler} {...props}>
      {children}
    </Dialog>
  );
};

TrackedDialog.Close = Dialog.Close;
